/* eslint-disable-next-line no-restricted-imports */
import styled from 'styled-components';

import colors from 'Static/typography/colors';

const PlayButtonContainer = styled.div`
	width: 2.25rem;
	height: 2.25rem;
	padding: 0.5rem 0.4375rem 0.5rem 0.5625rem;
	border-radius: 50%;
	background-color: ${colors.WHITE};
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 1;
	cursor: pointer;

	@media (min-width: 768px) {
		width: 3.25rem;
		height: 3.25rem;

		svg {
			width: 1.5rem;
			height: 1.5rem;
		}
	}
`;

type Props = {
	onClick: () => void;
};

const PlayButton = ({ onClick }: Props) => {
	return (
		<PlayButtonContainer onClick={onClick}>
			<svg
				xmlns='http://www.w3.org/2000/svg'
				width='20'
				height='20'
				fill='none'
				viewBox='0 0 20 20'
			>
				<path
					fill='#444'
					fillRule='evenodd'
					d='M3.75 4.71a1.563 1.563 0 012.316-1.368l9.616 5.289c1.08.593 1.08 2.144 0 2.738l-9.615 5.29a1.563 1.563 0 01-2.316-1.37L3.75 4.711z'
					clipRule='evenodd'
				></path>
			</svg>
		</PlayButtonContainer>
	);
};

export default PlayButton;
